import { Injectable } from '@angular/core';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { FCM } from '@capacitor-community/fcm';

import { DataService } from './data.service'; // Adjust the import path according to your project structure

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(private dataService: DataService) {}

  async initializePushNotifications() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
				
				const sub = this.dataService.sub;

				// Sanitize the topic using the regex pattern
				const santizedSub = this.sanitizeTopic(sub);
				
				// now you can subscribe to a specific topic
				FCM.subscribeTo({ topic: santizedSub })
					.then(r => console.log("subscribed to topic: " + sub))
					.catch(err => console.log(err));
					
      } else { // error        
				alert("error registering for push notifications");
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        console.log('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Add listener for push notifications
		PushNotifications.addListener('pushNotificationReceived',
			(notification: PushNotificationSchema) => {
				console.log('Push received: ' + JSON.stringify(notification));

				this.dataService.getDashboardFromAPI(); //sync app state with API
			}
		);
		
    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push notification has been tapped: ' + JSON.stringify(notification));
				this.dataService.getDashboardFromAPI(); //sync app state with API
      }
    );
	}
	
	// Method to sanitize the topic using the regex pattern
	sanitizeTopic(topic) {
			return topic.replace(/[^a-zA-Z0-9-_.~%]+/g, '');
	}

}