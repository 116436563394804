import { isPlatform } from '@ionic/angular';
import config from '../../capacitor.config';

export const domain = 'findbl0k.auth0.com';
export const clientId = '2TRyhyqfgEThu0QzYlfnm4fn865VOOt1';
export const audience = 'https://api.poursoulsystems.com';
const { appId } = config;

// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
const auth0Domain = domain;
const iosOrAndroid = isPlatform('ios') || isPlatform('android');

export const callbackUri = (() => {
  if (iosOrAndroid) {
    // For iOS or Android app
    return `${appId}://${auth0Domain}/capacitor/${appId}/callback`;
  } else {
      // Development environment
      //return 'http://localhost:4200/';
			return 'https://admin.poursoulsystems.com/';
  }
})();
