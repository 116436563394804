import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.poursoulsystems.hud',
  appName: 'Pour Soul HUD',
  webDir: 'www',
  plugins: {},
};

export default config;
